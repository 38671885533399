import * as yup from "yup";
import Select from "react-select";
import Toast from "components/Toast";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Countries from "../../assets/countries-region.json";

const Deposit = ({ player }) => {
  const [paymentmethod, setPaymentMethod] = useState(null);
  const [showBilling, setShowBilling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);

  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);
  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];
  const { t } = useTranslation();

  const depositSchema = yup.object().shape({
    amount: yup
      .number()
      .min(5, t("user-profile.errors.min.amount"))
      .typeError(t("user-profile.errors.required.amount"))
      .required(t("user-profile.errors.required.amount")),
  });

  const billingSchema = yup.object().shape({
    address: yup
      .string()
      .test("address", t("user-profile.errors.required.address"), (value) => {
        if (showBilling) {
          return value !== "";
        }
        return true;
      }),
    country: yup
      .string()
      .test("country", t("user-profile.errors.required.country"), (value) => {
        if (showBilling) {
          return value !== "";
        }
        return true;
      }),
    // zip_code: yup
    //   .string()
    //   .test("zip_code", t("user-profile.errors.required.zip-code"), (value) => {
    //     if (showBilling) {
    //       return value !== "";
    //     }
    //     return true;
    //   }),
    // state: yup
    //   .string()
    //   .test("state", t("user-profile.errors.required.state"), (value) => {
    //     if (showBilling) {
    //       return value !== "";
    //     }
    //     return true;
    //   }),
    // city: yup
    //   .string()
    //   .test("city", t("user-profile.errors.required.city"), (value) => {
    //     if (showBilling) {
    //       return value !== "";
    //     }
    //     return true;
    //   }),
    first_name: yup
      .string()
      .test(
        "first_name",
        t("user-profile.errors.required.first-name"),
        (value) => {
          if (showBilling) {
            return value !== "";
          }
          return true;
        }
      ),
    last_name: yup
      .string()
      .test(
        "last_name",
        t("user-profile.errors.required.last-name"),
        (value) => {
          if (showBilling) {
            return value !== "";
          }
          return true;
        }
      ),
  });

  const {
    register: depositRegister,
    handleSubmit: depositSubmit,
    reset: depositReset,
    trigger: depositTrigger,
    getValues: depositGetValues,
    formState: { errors: depositErrors },
  } = useForm({
    resolver: yupResolver(depositSchema),
  });

  const {
    register: billingRegister,
    handleSubmit: billingSubmit,
    reset: billingReset,
    control: billingControl,
    formState: { errors: billingErrors },
  } = useForm({
    resolver: yupResolver(billingSchema),
  });

  const handleDeposit = async (e) => {
    e.preventDefault();
    if (
      e.target.deposit_amount?.value === "" ||
      e.target.deposit_amount?.value === 0
    )
      return;
    const data = {
      amount: e.target.deposit_amount.value,
    };

    submitDeposit(data);
    e.target.deposit_amount.value = "";
  };

  async function submitDeposit(data) {
    try {
      console.log(data);
    } catch (error) {
      notify("error", t("user-profile.notifications.deposit-error"));
    }
  }

  const decideDeposit = (data) => {
    const newData = {
      first_name: data.first_name || player.first_name,
      last_name: data.last_name || player.last_name,
      email: player.email,
      phone_number: data.phone_number || player.phone_number,
      address: data.address,
      country: data.country,
      // zip_code: data.zip_code,
      // state: data.state,
      // city: data.city,
      amount: amount,
    };

    if (paymentmethod === "broadpay") {
      sparcoCheckout(newData);
    } else if (paymentmethod === "truevo-cc") {
      truevoCheckout(newData);
    } else if (paymentmethod === "simbalotto-gw") {
      simbalottoGateway(newData);
    }
  };

  useEffect(() => {
    // Load the LencoPay script
    const script = document.createElement("script");
    script.src = "https://pay.lenco.co/js/v1/inline.js";
    script.async = true;
    script.onload = () => {
      console.log("LencoPay script loaded successfully");
    };

    script.onerror = () => {
      console.error("Failed to load the LencoPay script");
    };
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  const sparcoCheckout = async (data) => {
    if (!data.amount) return;

    try {
      setLoading(true);
      await fetch("/api/v1/lenco/deposit", {
        method: "POST",
        body: JSON.stringify({ amount: data.amount }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then(async (dt) => {
          await getPaidWithLenco(dt.transactionID, dt.key, data);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.log(error);
      notify("error", error.message);
    }
  };

  const getPaidWithLenco = async (txnRef, key, data) => {
    if (window.LencoPay) {
      window.LencoPay.getPaid({
        key: key.toString(),
        reference: txnRef,
        email: data.email,
        amount: parseInt(data.amount),
        currency: "EUR",
        channels: ["card"],
        customer: {
          firstName: data?.first_name,
          lastName: data?.last_name,
          phone: data?.phone_number,
        },
        billing: {
          streetAddress: data?.address,
          postalCode: data?.zip_code,
          country: data?.country,
          state: data?.state,
          city: data?.city,
        },
        onSuccess: function (response) {
          fetch("/api/v1/lenco/status", {
            method: "POST",
            body: JSON.stringify({ txnRef }),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          })
            .then(async (response) => {
              if (response.ok) {
                return response.json();
              }
              return response.text().then((text) => {
                throw new Error(text);
              });
            })
            .then(async (resp) => {
              depositReset();
              billingReset();
              notify("success", resp.message);
              window.location.reload();
            })
            .finally(() => setLoading(false));
        },
        onClose: function () {
          notify("error", "Payment was not completed, window closed.");
        },
        onConfirmationPending: function () {
          notify(
            "error",
            "Your purchase will be completed when the payment is confirmed"
          );
        },
      });
    } else {
      console.error("LencoPay is not loaded");
    }
  };

  const truevoCheckout = async (data) => {
    if (!data.amount) return;

    try {
      const checkoutData = {
        amount: data.amount,
        customerFirstName: data.first_name,
        customerLastName: data.last_name,
        customerEmail: data.email,
        customerPhone: data.phone_number,
        customerAddr: data.address,
        customerCity: data.city,
        customerState: data.state,
        customerPostalCode: data.zip_code,
        customerCountryCode: data.country,
      };
      setLoading(true);

      const response = await fetch("/api/v1/truevo/deposit", {
        method: "POST",
        body: JSON.stringify(checkoutData),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const htmlContent = await response.text();
      submitFormToNewPage(htmlContent);
    } catch (error) {
      console.log(error);
      notify("error", error.message);
    }
  };

  const simbalottoGateway = async (data) => {
    if (!data.amount) return;

    try {
      setLoading(true);

      const response = await fetch("/api/v1/simbalotto-gw/deposit", {
        method: "POST",
        body: JSON.stringify({ amount: data.amount }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const resp = await response.json();
      if (resp && resp.redirect_url) {
        window.location.href = resp.redirect_url;
      }
    } catch (error) {
      console.log(error);
      notify("error", error.message);
    }
  };

  async function submitFormToNewPage(htmlContent) {
    // Use DOMParser to parse the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Extract form action URL
    const formAction = doc.querySelector("form").action;

    // Extract all input elements
    const inputs = doc.querySelectorAll("input");

    // Create a new form element
    const form = document.createElement("form");
    form.method = "POST";
    form.action = formAction;
    // form.target = "_blank"; // Ensure it opens in a new tab

    // Append input elements to the form
    inputs.forEach((input) => {
      const inputElement = document.createElement("input");
      inputElement.type = input.type;
      inputElement.name = input.name;
      inputElement.value = input.value;
      form.appendChild(inputElement);
    });

    // Append the form to the document body
    document.body.appendChild(form);

    // Submit the form
    form.submit();

    // Remove the form from the body after submission
    setTimeout(() => {
      document.body.removeChild(form);
    }, 100); // Delay to ensure form submission
  }

  const countries = Countries.map((country) => {
    return {
      value: country.countryShortCode,
      label: country.countryName,
      regions: country.regions.map((region) => {
        return { value: region.name, label: region.name };
      }),
    };
  });

  return (
    <>
      {/* deposit input */}
      <div className="card bg-secondary shadow">
        <div className="card-header bg-simbalotto-green border-0">
          <h3 className="mb-0 text-capitalize text-dark">
            {t("user-profile.headings.deposit")}
          </h3>
        </div>
        <div className="card-body">
          <form onSubmit={depositSubmit(handleDeposit)}>
            <h6 className="heading-small text-muted mb-4">
              {t("user-profile.headings.deposit-info")}
            </h6>
            <div className="pl-lg-4">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label
                      className="form-control-label text-muted"
                      htmlFor="input-amount"
                    >
                      {t("user-profile.fields.amount")}
                    </label>
                    <input
                      className="form-control form-control-alternative"
                      id="input-amount"
                      placeholder="Amount"
                      type="number"
                      name="deposit_amount"
                      {...depositRegister("amount")}
                    />

                    {depositErrors.amount && (
                      <span className="text-danger">
                        {depositErrors.amount.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 m-auto">
                  <button
                    className="btn btn-simbalotto-orange text-dark"
                    type="submit"
                    onClick={async (e) => {
                      const result = await depositTrigger("amount");
                      result && setAmount(depositGetValues("amount"));
                      result && e.target.setAttribute("data-toggle", "modal");
                      result &&
                        e.target.setAttribute("data-target", "#depositModal");
                    }}
                  >
                    <span className="btn-inner--icon text-dark">
                      <i className="fas fa-money-bill"></i>
                    </span>
                    <span className="btn-inner--text text-dark">
                      {t("user-profile.buttons.deposit")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* deposit modal */}
      <div
        className="modal fade"
        id="depositModal"
        tabIndex="-1"
        aria-labelledby="depositModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-capitalize"
                id="depositModalCenterTitle"
              >
                {t("user-profile.deposit.title")}
                <p className="row mx-3 my-2">
                  ({t("user-profile.deposit.disclaimer")})
                </p>
              </h5>
              <span
                aria-hidden="true"
                className="close fa-2x"
                data-dismiss="modal"
                aria-label="Close"
                role="button"
              >
                &times;
              </span>
            </div>

            <div className="modal-body">
              <div className="row">
                {/* broadpay credit card */}
                <label
                  className="col-6 mb-2"
                  role="button"
                  htmlFor="input-broadpay"
                  onClick={() => {
                    setPaymentMethod("broadpay");
                    setShowBilling(true);
                  }}
                >
                  <div className="card bg-secondary shadow">
                    <div
                      className={`card-body border-0  
                      ${
                        paymentmethod === "broadpay"
                          ? "bg-simbalotto-green"
                          : ""
                      }
                    `}
                    >
                      <div className="row align-items-center">
                        <div className="col-12 text-center">
                          <i className="fas fa-credit-card fa-3x text-primary" />
                        </div>
                        <div className="col-12">
                          <h3 className="mb-0 text-center">Lenco (BroadPay)</h3>
                          <p>{t("user-profile.deposit.broadpay-desc")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="payment_method"
                    value="broadpay"
                    id="input-broadpay"
                    hidden
                  />
                </label>

                {/* Truevo Credt Card */}
                <label
                  className="col-6 mb-2"
                  role="button"
                  htmlFor="input-truevo-cc"
                  onClick={() => {
                    setPaymentMethod("truevo-cc");
                    setShowBilling(false);
                  }}
                >
                  <div className="card bg-secondary shadow">
                    <div
                      className={`card-body border-0  
                      ${
                        paymentmethod === "truevo-cc"
                          ? "bg-simbalotto-green"
                          : ""
                      }
                    `}
                    >
                      <div className="row align-items-center">
                        <div className="col-12 text-center">
                          <i className="fas fa-credit-card fa-3x text-primary" />
                        </div>
                        <div className="col-12">
                          <h3 className="mb-0 text-center">Truevo</h3>
                          <p>{t("user-profile.deposit.truevo-desc")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="payment_method"
                    value="truevo-cc"
                    id="input-truevo-cc"
                    hidden
                  />
                </label>

                {/* Simbalotto gateway */}
                <label
                  className="col-6 mb-2"
                  role="button"
                  htmlFor="input-simbalotto-gw"
                  onClick={() => {
                    setPaymentMethod("simbalotto-gw");
                    setShowBilling(false);
                  }}
                >
                  <div className="card bg-secondary shadow">
                    <div
                      className={`card-body border-0  
                      ${
                        paymentmethod === "simbalotto-gw"
                          ? "bg-simbalotto-green"
                          : ""
                      }
                    `}
                    >
                      <div className="row align-items-center">
                        <div className="col-12 text-center">
                          <i className="fas fa-credit-card fa-3x text-primary" />
                        </div>
                        <div className="col-12">
                          <h3 className="mb-0 text-center">
                            {t("user-profile.deposit.simbalotto-gw")}
                          </h3>
                          <p>{t("user-profile.deposit.simbalotto-gw-desc")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="payment_method"
                    value="simbalotto-gw"
                    id="input-simbalotto-gw"
                    hidden
                  />
                </label>
              </div>

              <form className="row" onSubmit={billingSubmit(decideDeposit)}>
                {showBilling && (
                  <>
                    {/* input for first-name */}
                    <div className="col-12">
                      <label
                        className="form-control-label text-dark"
                        htmlFor="input-first-name"
                      >
                        {t("user-profile.fields.first-name")}
                      </label>
                      <input
                        className="form-control form-control-alternative"
                        id="input-first-name"
                        placeholder={t("user-profile.fields.first-name")}
                        type="text"
                        defaultValue={player.first_name || ""}
                        {...billingRegister("first_name")}
                      />

                      <span className="text-danger">
                        {billingErrors?.first_name?.message}
                      </span>
                    </div>

                    {/* input for last-name */}
                    <div className="col-12">
                      <label
                        className="form-control-label text-dark"
                        htmlFor="input-last-name"
                      >
                        {t("user-profile.fields.last-name")}
                      </label>
                      <input
                        className="form-control form-control-alternative"
                        id="input-last-name"
                        placeholder={t("user-profile.fields.last-name")}
                        type="text"
                        defaultValue={player.last_name || ""}
                        {...billingRegister("last_name")}
                      />

                      <span className="text-danger">
                        {billingErrors?.last_name?.message}
                      </span>
                    </div>

                    {/* phone number */}
                    <div className="col-12">
                      <label
                        className="form-control-label text-dark"
                        htmlFor="input-phone-number"
                      >
                        {t("user-profile.fields.phone-number")}
                      </label>
                      <input
                        className="form-control form-control-alternative"
                        id="input-phone-number"
                        placeholder={t("user-profile.fields.phone-number")}
                        type="text"
                        defaultValue={player.phone_number || ""}
                        {...billingRegister("phone_number")}
                      />

                      <span className="text-danger">
                        {billingErrors?.phone_number?.message}
                      </span>
                    </div>

                    {/* input for address */}
                    <div className="col-12">
                      <label
                        className="form-control-label text-dark"
                        htmlFor="input-address"
                      >
                        {t("user-profile.fields.address")}
                      </label>
                      <input
                        className="form-control form-control-alternative"
                        id="input-address"
                        placeholder={t("user-profile.fields.address")}
                        type="text"
                        defaultValue={player.address || ""}
                        {...billingRegister("address")}
                      />

                      <span className="text-danger">
                        {billingErrors?.address?.message}
                      </span>
                    </div>

                    {/* input for country */}
                    <div className="col-12">
                      <label
                        className="form-control-label text-dark"
                        htmlFor="input-country"
                      >
                        {t("user-profile.fields.country")}
                      </label>

                      <Controller
                        name="country"
                        control={billingControl}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            inputRef={ref}
                            value={countries.filter((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                            options={countries}
                            placeholder={t("user-profile.fields.country")}
                          />
                        )}
                      />

                      <span className="text-danger">
                        {billingErrors?.country?.message}
                      </span>
                    </div>

                    {/* input for zip code */}
                    {/* <div className="col-12">
                      <label
                        className="form-control-label text-dark"
                        htmlFor="input-zip-code"
                      >
                        {t("user-profile.fields.postal-code")}
                      </label>
                      <input
                        className="form-control form-control-alternative"
                        id="input-zip-code"
                        placeholder={t("user-profile.fields.postal-code")}
                        type="text"
                        defaultValue={player.postal_code || ""}
                        {...billingRegister("zip_code")}
                      />

                      <span className="text-danger">
                        {billingErrors?.zip_code?.message}
                      </span>
                    </div>  */}

                    {/* input for state */}
                    {/* <div className="col-12">
                      <label
                        className="form-control-label text-dark"
                        htmlFor="input-state"
                      >
                        {t("user-profile.fields.state")}
                      </label>
                      <input
                        className="form-control form-control-alternative"
                        id="input-state"
                        placeholder={t("user-profile.fields.state")}
                        type="text"
                        defaultValue={player.region || ""}
                        {...billingRegister("state")}
                      />

                      <span className="text-danger">
                        {billingErrors?.state?.message}
                      </span>
                    </div> */}

                    {/* input for city */}
                    {/* <div className="col-12">
                      <label
                        className="form-control-label text-dark"
                        htmlFor="input-city"
                      >
                        {t("user-profile.fields.city")}
                      </label>
                      <input
                        className="form-control form-control-alternative"
                        id="input-city"
                        placeholder={t("user-profile.fields.city")}
                        type="text"
                        defaultValue={player.city || ""}
                        {...billingRegister("city")}
                      />

                      <span className="text-danger">
                        {billingErrors?.city?.message}
                      </span>
                    </div> */}
                  </>
                )}

                <div className="col-12 mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={loading}
                  >
                    {t("user-profile.proceed")}
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deposit;
